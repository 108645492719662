/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Kbd} from '@chakra-ui/react';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    pre: "pre",
    code: "code",
    h3: "h3",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Emoji Keyboard Shortcut"), "\n", React.createElement(_components.p, null, React.createElement(Kbd, null, "ctl"), " + ", React.createElement(Kbd, null, "cmd"), " + ", React.createElement(Kbd, null, "space")), "\n", React.createElement(_components.h2, null, "Change Default Icons"), "\n", React.createElement(_components.p, null, "The default icons for Macos as of 2023 are located here 👇"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "/System/Library/CoreServices/CoreTypes.bundle/Contents/Resouces\n")), "\n", React.createElement(_components.p, null, "As of OS 10.11 to change default icons system integrity protection have to be disable."), "\n", React.createElement(_components.h3, null, "Temporarily Disable System Integrity Protection"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "boot into recovery mode by holding ", React.createElement(Kbd, null, "CMD"), " + ", React.createElement(Kbd, null, "r"), " during restart"), "\n", React.createElement(_components.li, null, "in terminal run ", React.createElement(_components.code, null, "csrutil disable")), "\n", React.createElement(_components.li, null, "reboot and edit icons at the path above"), "\n", React.createElement(_components.li, null, "when done boot back into recovery mode and run ", React.createElement(_components.code, null, "csrutil enable")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
